@use '/styles/mixin/fonts.module.scss' as *;

.drive-novated-lease-header-section {
  &__container {
    @apply flex;
    @apply flex-col;
    @apply w-full;
    @apply bg-blue-faint;
    @apply rounded-2xl;
    @apply rounded-b-none;
    @apply px-6;
    @apply pt-7;
    @screen lmd {
      @apply px-8;
      @apply pt-10;
    }
  }

  &__separator-header {
    @apply w-[62px];
    @apply border-x-2;
    @apply border-y-[2.5px];
    @apply border-blue-light;
    @apply rounded-lg;
    @apply mx-auto;
    @apply -mt-[4px];
    @screen lmd {
      @apply hidden;
    }
  }

  &__wrapper {
    @apply flex;
    @apply w-full;
    @apply flex-col;
    @apply gap-5;
    @apply pb-5;
    @apply pt-2;
    @screen lmd {
      @apply flex-row;
      @apply pt-0;
      @apply pb-4;
    }
  }

  &__info {
    @apply flex;
    @screen lmd {
      @apply w-[60%];
    }
    @screen lg {
      @apply w-[55%];
    }
  }

  &__img-wrapper {
    @apply h-full;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-center;
    @apply w-[92px];
    @apply relative;
    @screen lmd {
      @apply min-w-[114px];
    }

    img {
      @apply bg-white;
      @apply min-w-[92px];
      @apply min-h-[51px];
      @apply rounded-lg;
      @screen lmd {
        @apply min-w-[114px];
        @apply min-h-[64px];
      }
      @screen xl {
        @apply min-w-[118px];
        @apply min-h-[69px];
      }
    }
  }

  &__sample-image-text {
    @apply text-center;
    @apply p-1px;
    @apply bottom-0;
    @apply w-full;
    @include font-caption('mobile');
    @screen lmd {
      @include font-caption('desktop');
    }
    @apply bg-blue-faint;
    @apply text-blue-dark;
  }

  &__description {
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    @apply pl-4;
    @apply mt-1;
    @screen lmd {
      @apply mt-0;
      @apply flex-row;
      @apply items-center;
      @apply pl-6;
    }
  }

  &__header {
    h3 {
      @apply m-0;
      @include font-subtext('mobile', 'large');
    }
    p {
      @apply m-0;
      @apply mt-1;
      @include font-text('mobile', 'medium');
    }

    @screen lmd {
      h3 {
        @apply m-0;
        @include font-subtext('desktop', 'large');
      }
      p {
        @apply m-0;
        @apply mt-2;
        @include font-text('desktop', 'medium');
      }
    }
  }
  &__extra-info {
    @apply flex;
    @apply flex-col;
    @apply gap-y-2;

    @screen lmd {
      @apply w-1/2;
      @apply pl-4;
      @apply mt-3;
    }
  }

  &__price-info {
    @apply w-full;
    @apply flex;

    &-label {
      @apply w-[160px];
      @apply flex;
      @apply items-center;
      @apply max-w-40;
      @include font-text('mobile', 'medium');
      @screen lmd {
        @apply w-[170px];
        @apply max-w-full;
        @include font-text('desktop', 'medium');
      }
    }

    &-value {
      @apply flex;
      @apply items-center;
      @apply w-[110px];
      @include font-heading('mobile', 'h4');
      @screen lmd {
        @include font-heading('desktop', 'h4');
      }
    }

    &__info {
      @apply cursor-pointer;
      @apply w-8;
      @apply h-8;
      @apply flex;
      @apply items-center;
      @apply justify-center;
      @apply bg-transparent;
      @apply rounded;

      &:hover {
        @apply bg-blue-200;
      }

      &__icon {
        @apply h-6;
        @apply w-6;
      }
    }
  }

  &__separator {
    @apply m-0;
    @apply w-full;
    @apply border-b;
    @apply border-blue-light;
    @apply mx-auto;
  }

  &__modal-background {
    @screen lmd {
      @apply bg-transparent;
    }
  }
}
