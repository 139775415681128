@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/truncate.module.scss' as *;

.d-container {
  @apply w-full;
  @apply p-4;
  @apply flex;
  @apply gap-4;
  @apply items-start;
  @apply rounded-lg;
  @apply border-gray-200;
  @apply border;
  @apply relative;
  @screen lg {
    @apply gap-4;
  }
  &:hover {
    @apply cursor-pointer;
    span {
      @apply block;
    }
  }
  &__no-img {
    @apply leading-loose;
    @apply justify-between;
    @apply py-3;
  }
  &__img-wrapper {
    @apply flex;
    @apply shrink-0;
    @apply w-24;
    @apply h-16;
    @screen md {
      @apply w-[98px];
    }
    @screen lg {
      @apply w-[122px];
      @apply h-[72px];
    }
    & img {
      @apply w-full;
      @apply h-auto;
      @apply rounded-lg;
    }
  }
  span {
    @apply hidden;
  }
  &:hover {
    @apply bg-gray-100;
    @apply text-blue-dark;
  }
  &__text-wrapper {
    @apply w-full;
  }
  &__title {
    @apply font-semibold;
    @screen lg {
      @apply mt-2;
    }
  }
  &__title-no-img {
    @apply mb-0;
  }
  &__body {
    @apply text-gray-400;
    @include font-text('mobile', 'small'); // 11px
    @include truncate(2);
    @screen md {
      @apply hidden;
      @include font-text('desktop', 'small'); // 11px
      @include truncate(1);
    }
  }
  &__icon {
    @apply max-h-[32px];
    @apply max-w-[32px];
  }
}
