@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  &:hover {
    @apply cursor-pointer;
  }

  @apply w-[156px];
  @apply flex;
  @apply flex-col;
  @apply h-[190px];
  @apply items-center;
  @apply justify-evenly;
  @apply border;
  @apply rounded-lg;
  @apply p-2;

  @screen lg {
    @apply h-[204px];
  }

  &:hover {
    @apply shadow-lg; // Optional: Any existing Tailwind shadows can be kept
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04); // Custom box shadow
    @apply text-blue-dark;
  }

  &__img-wrapper {
    @apply mb-4;
    @apply max-h-[80px];
    & img {
      @apply m-auto;
    }
  }

  &__img-wrapper--make {
    @apply w-[80px];
  }

  &__img-wrapper--model {
    @apply w-full;
  }

  &__title {
    @apply text-center;
    @apply font-semibold;
    &--showrooms-make {
      @include font-subtext('desktop', 'large');
      @apply pb-4;
      @apply text-[14px];
    }
    &--showrooms-model {
      @include font-subtext('desktop', 'large');
    }
  }

  &__body {
    @apply tracking-[0.24px];
    @include font-subtext('mobile', 'medium');
    @apply font-normal;

    @screen lg {
      @include font-subtext('mobile', 'medium');
      @apply font-normal;
    }
    @apply text-black;
    @apply text-center;
    @apply block;
    // pseduo element to keep the heights consistent
    &--model {
      @apply text-center;
      &::after {
        @apply block;
        @apply h-auto;
        visibility: hidden;
        content: 'placeholder text';
      }
    }
    &--mrlp {
      @include font-text('mobile', 'small'); // 11px
      @apply text-gray-400;
    }
  }
}
