/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin progress($border: true) {
  @apply border-2;
  @apply w-5;
  @apply h-5;
  @apply rounded-2xl;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  @apply border-teal-dark;
  @if $border == false {
    @apply border-transparent;
  }
  border-top: 2px solid #fff;
}

@mixin progress-2 {
  @apply border-2;

  @apply w-5;
  @apply h-5;
  @apply rounded-2xl;
  border-top: 2px solid #fff;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
