@use '/styles/mixin/fonts.module.scss' as *;
.drive-dropdown {
  &__header {
    @include font-button;
  }
  &__heading {
    @apply flex;
    @apply items-center;
    @include font-subtext('mobile', 'large');
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }

  &__show-items {
    @apply z-40;
  }

  &__expand-icons {
    @apply h-7;
    @apply fill-current;
    @apply text-3xl;
  }
  &__error-msg {
    @apply absolute;
    @apply left-0;
    @apply bottom-0;
    @apply text-coral-normal;
    @apply text-xs;
    @apply -mb-5;
  }
}
