@use '/styles/mixin/fonts.module.scss' as *;

.drive-call-dealer {
  &__wrapper {
    @apply w-full;
    @apply flex;
    @apply flex-col;
  }

  &__separator {
    @apply flex;
    @apply gap-[18px];
    @apply items-center;
    @apply pt-3;
    @apply pb-[18px];

    hr {
      @apply w-full;
      @apply border-t-2;
      @apply border-blue-light;
    }
    span {
      @apply w-auto;
      @include font-subtext('desktop', 'medium');
      @apply text-blue-light;
    }

    @screen lmd {
      @apply hidden;
    }
  }

  &__separator-full {
    @apply hidden;
    @apply pt-8;
    @apply pb-[22px];
    hr {
      @apply w-full;
      @apply border-t-2;
      @apply border-blue-light;
      @apply m-0;
    }
    @screen lmd {
      @apply block;
    }
  }

  &__tc {
    @apply py-2;
    @apply pt-8;
    @apply text-center;
    @apply text-gray-500;
    @include font-caption('mobile', 'normal');
    @screen lmd {
      @apply hidden;
    }
  }

  &__contact-info {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @screen md {
      @apply flex-row;
      @apply gap-[77px];
      @apply items-center;
    }

    &__description {
      @apply hidden;

      @screen lmd {
        @apply block;
      }
      @apply pointer-events-none;
      &-header {
        @apply flex;

        h3 {
          @apply ml-2;
          @apply mt-1px;
          @apply inline;
          @include font-standfirst('mobile');
        }
      }

      p {
        @include font-text('mobile', 'medium');
        @apply mt-2;
        @apply ml-10;
      }
    }

    &-button__wrapper {
      @screen md {
        @apply w-1/2;
      }
    }

    &-button__content {
      @apply w-full;
      @apply px-[21px];
      @apply rounded-lg;
      @include font-button;
      @apply h-12;
      @apply min-h-12;
      @apply min-w-[152px];
      @apply flex;
      @apply flex-1;
      @apply gap-x-2;
      @apply items-center;
      @apply justify-center;
      @apply flex-nowrap;
      @apply whitespace-nowrap;
      @apply text-blue-dark;
      @apply border-2;
      @apply border-blue-dark;

      &:hover {
        @apply border-[#497BD8];
      }
    }

    &-button__text {
      @include font-button('desktop');
      &--mobile {
        @apply inline;
        @screen lg {
          @apply hidden;
        }
      }
      &--non-mobile {
        @apply hidden;
        @screen lg {
          @apply inline;
        }
      }
    }
    &-button-loading {
      @apply opacity-85;
      @apply pointer-events-none;
    }
  }
  &__icon {
    @apply h-8;
    @apply w-8;
  }
}
