@use '/styles/mixin/fonts.module.scss' as *;

.drive-enquiry-form {
  @apply flex;
  @apply flex-col;
  @apply flex-wrap;
  @apply items-end;

  &__container {
    @apply flex;
    @apply flex-col;
    @apply mt-6;
    @apply relative;
    @screen lmd {
      @apply flex-row;
      @apply items-start;
    }
  }

  &__fields {
    @apply w-full;
    @apply grid;

    grid-template-columns: 52.47% 13.27% 34.26%;
    grid-template-rows: 90px 90px 90px 1fr;

    @screen lmd {
      grid-template-columns: 190px 54px 119px 1fr;
    }

    :global(.firstName) {
      grid-row: 1;
      grid-column: 1;
      @apply pr-4;
    }

    :global(.lastName) {
      grid-row: 1;
      grid-column: 2 / 4;
    }

    :global(.email) {
      grid-row: 2;
      grid-column: 1 / 4;
    }

    // Styles for comments field
    :global(.comments) {
      @apply h-full;
      grid-row: 4;
      grid-column: 1 / 4;
      @apply min-h-[88px];
      @screen lmd {
        @apply min-h-[104px];
      }
    }

    // Styles for checkbox fields
    :global(.checkboxes) {
      grid-row: 5;
      grid-column: 1 / 4;
      @apply mt-2;

      @screen lmd {
        @apply mt-0;
        grid-row: 1;
        grid-column: 5 / 5;
        @apply ml-[67px];
      }
    }

    // Styles for phone number field
    :global(.phone) {
      grid-row: 3;
      grid-column: 1 / 3;
      @apply pr-4;

      [class^='animated-input_drive-form-animated-input__input'] {
        @apply pr-0;
        @apply pl-3;
      }
    }

    // Styles for postcode field
    :global(.postcode) {
      grid-row: 3;
      grid-column: 3 / 4;
    }
  }

  &__buttons {
    grid-row: 6;
    grid-column: 1 / 4;
    @screen lmd {
      grid-row: 3;
      grid-column: 5 / 5;
      @apply ml-[67px];
      @apply mt-[42px];
      @apply max-w-[311px];
    }
  }

  &__agree-label {
    grid-row: 7;
    grid-column: 1 / 4;
    @apply py-2;
    @apply pt-4;
    @apply text-center;
    @include font-caption('mobile', 'normal');
    @screen lmd {
      @apply text-left;
      grid-row: 2;
      grid-column: 5 / 5;
      @apply ml-[67px];
      @apply mt-10;
    }
  }
}
