@use '/styles/mixin/categoryLabel.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;

.drive-cfs__lightbox-form {
  @apply w-full;
  &__wrapper {
    @apply rounded-2xl;
    @apply bg-blue-50;
    @apply p-6;
  }

  &__header {
    @include font-heading('desktop', 'h5');
    @apply mt-0;
    @apply mb-6;
    @apply text-center;
  }

  // Overwrite styles for call dealer button
  &__call-dealer {
    [class^='call-dealer_drive-call-dealer__separator'] {
      @apply flex;
    }
    [class^='call-dealer_drive-call-dealer__separator-full'] {
      @apply hidden;
    }

    [class^='call-dealer_drive-call-dealer__contact-info__description'] {
      @screen lmd {
        @apply hidden;
      }
    }

    [class^='call-dealer_drive-call-dealer__tc'] {
      @screen lmd {
        @apply block;
      }
    }

    [class^='call-dealer_drive-call-dealer__contact-info'] {
      h3,
      p {
        @screen md {
          @apply w-full;
        }
      }
      [class^='call-dealer_drive-call-dealer__contact-info-button__wrapper'] {
        @apply w-full;
      }
    }
  }
  &__agree-label {
    @apply py-2;
    @apply pt-4;
    @apply text-center;
    @apply text-gray-500;
    @include font-caption('mobile', 'normal');
  }
}
