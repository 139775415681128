@use '/styles/mixin/fonts.module.scss' as *;

.drive-breadcrumbs {
  @apply hidden;
  @apply items-center;
  @apply pt-1;
  @apply pb-4;
  @screen md {
    @apply flex;
  }
  &__home-icon {
    @apply fill-current;
    @apply text-blue-dark;
    @apply text-xl;
    @apply h-3.5;
    @apply w-3.5;
    &:hover {
      @apply text-blue-normal;
    }
  }
  &__arrow-forward {
    @apply mx-4;
    @apply fill-current;
  }
  &__arrow-back {
    @apply fill-current;
    @apply mr-2;
  }
  &__title {
    @apply capitalize;
    @screen md {
      @include font-caption('desktop', 'normal');
    }
  }
  &__link {
    @include font-caption('mobile', 'normal');
  }
  &__back {
    @screen md {
      @include font-caption('desktop', 'normal');
    }
  }
  &__btyb {
    @apply ml-auto;
    img {
      @apply max-h-[44px];
      @screen md {
        @apply max-h-[48px];
      }
    }
  }

  &:global(.showrooms-breadcrumbs) {
    @apply max-w-[1240px];
    @apply mx-auto;
    @apply hidden;
    @apply px-5;
    @screen md {
      @apply flex;
      @apply px-9;
      @apply py-4;
    }
    @screen lg {
      @apply px-[112px];
    }
    @screen xl {
      @apply px-0;
    }
  }

  &:global(.showrooms-breadcrumbs-with-pto) {
    @apply max-w-[1240px];
    @apply mx-auto;

    [class*='breadcrumbs_drive-breadcrumbs'] {
      @apply px-0;
    }
  }

  &:global(.cfs-breadcrumbs) {
    @apply pb-1;
  }
}
