@mixin gallery {
  @apply w-full;
  @apply relative;
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply text-white;
  @apply text-15px;
  @apply font-semibold;

  &[class~='block'] {
    [class~='lightbox__open-btn'] {
      bottom: rem(20);
    }
  }
}
