@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/truncate.module.scss' as *;

.d-container {
  @apply flex;
  @apply flex-col;
  &--small {
    @apply h-44;
  }

  &--large {
    @apply h-48;
  }
  &:hover {
    @apply cursor-pointer;

    div {
      @apply underline;
    }
  }
  @apply max-w-[156px];
  p {
    @apply m-0;
  }
  &__image-wrapper {
    @apply relative;
    @apply mb-4;
    > img {
      @apply h-[88px];
      @apply rounded-lg;
    }
  }
  &__video-icon-wrapper {
    @apply absolute;
    @apply top-1/2;
    @apply left-1/2;
    @apply rounded-full;
    @apply bg-white;
    @apply w-7;
    @apply h-7;
    transform: translate(-50%, -50%);
    img {
      transform: translate(50%, 50%);
    }
  }

  &__label {
    // TODO: Need a new mixin for the new font styles
    @apply font-sans;
    @apply text-xs;
    @apply uppercase;
    @apply -translate-y-1/2;
    @apply absolute;
    @apply px-[10px];
    @apply py-[2.5px];
    @apply rounded-sm;
    &--default {
      @apply bg-black;
      @apply text-white;
    }
    &--stock {
      @apply bg-gray-200;
    }
    &--nused {
      @apply text-white;
      background: var(--Nused-Gradient, linear-gradient(88deg, #0a256e 22.7%, #2960c5 202.04%));
    }
  }

  &__title {
    @include font-text('mobile', 'medium'); // 14px
    @include truncate(2);
    @apply leading-5;
    @apply mb-2;
    @apply flex-grow;
    @apply overflow-hidden;
    @apply text-start;
  }
  &__disclaimer {
    @include font-text('mobile', 'small'); // 11px
    @apply text-gray-400;
  }
  &__date {
    // TODO: Confirm and add correct font mixin
    @include font-text('desktop', 'small'); // 11px
    @apply text-gray-400;
  }
  &__price {
    @include font-subtext('mobile', 'medium');
    @apply self-start;
    @apply text-start;
  }
}
