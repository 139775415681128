@use '../lightbox-mixins.module.scss' as *;
@use '/styles/mixin/scroll.module.scss' as *;

.lightbox-enquiry-form {
  &--side {
    @apply hidden;
    @screen lg {
      @apply flex;
      @apply w-[360px];
      @apply min-w-[360px];
    }
    height: calc(100% - $navHeight - 106px);
    @apply mr-5;
    @apply ml-0.5;
    @apply overflow-hidden;
    @apply rounded-2xl;

    & > div {
      @apply shadow-none;
      @apply border-0;
      @apply text-black;
      @include custom-scroll;
      @apply overflow-y-scroll;
      @apply h-full;

      /* Hide scrollbar */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        @apply hidden; /* Webkit-based browsers */
      }
    }
    [class^='lightbox-form_drive-cfs__lightbox-form__wrapper'] {
      @apply pr-4; //override spacing to accommodate scroll bar that often appears on the side
    }
  }
  &--hidden {
    @apply hidden;
  }
}
