@use '/styles/mixin/fonts.module.scss' as *;

.drive-contact-seller-confirmation {
  &__message-confirmation {
    [class^='confirmation-message_drive-cfs__confirmation-message__wrapper'] {
      @screen lg {
        @apply flex;
        @apply flex-col;
        @apply justify-between;
        @apply w-full;
        @apply p-6;
        @apply pt-8;
      }

      [class^='confirmation-message_drive-cfs__confirmation-message__heading'] {
        @screen lg {
          @include font-heading('desktop', 'h3');
        }
      }

      [class^='confirmation-message_drive-cfs__confirmation-message__msg'] {
        @screen lg {
          @include font-text('desktop', 'medium');
        }
      }

      :global(.wp-block-columns) {
        @screen lg {
          @apply flex-col;
        }

        :global(.wp-block-column) {
          &:not(:last-child) {
            @screen lg {
              @apply mb-6;
            }
          }
        }
      }

      :global(.wp-block-cover) {
        @apply min-h-[192px] #{!important};
        @apply h-[192px] #{!important};
        @apply p-7;
      }

      [class^='form-details_drive-form-details__details-form__name-email'] {
        @screen lg {
          @apply w-full;
        }
      }

      [class^='form-details_drive-form-details__details-form__phone-postcode'] {
        @screen lg {
          @apply flex-row;
          @apply justify-between;
          @apply w-full;

          div:nth-child(1) {
            @apply w-[65%];
            @apply mr-1;
          }

          div:nth-child(2) {
            @apply w-[32%];
          }
        }
      }

      [class^='form-details_drive-form-details__details-form__message'] {
        @screen lg {
          @apply w-full;
        }
      }

      [class^='confirmation-message_drive-cfs__confirmation-message__cta-blocks'] {
        @screen lg {
          @apply h-[440px];
        }
      }

      [class^='form-details_drive-form-details__details-field'] {
        p {
          @screen lg {
            @apply max-h-[140px];
          }
        }
      }
    }
  }
}
