@use '../common/index' as *;

.d-vehicle-list-header {
  @apply hidden;

  @screen md {
    @apply grid;
  }

  grid-template-columns: $vehicleListGridTemplateColumns;
  grid-template-areas: '. make-and-model price weekly est-tax-savings .';
  @apply place-items-center;
  @apply text-center;
  @apply bg-gray-100;
  @apply text-gray-500;
  @apply font-semibold;
  padding: 0.75rem 0;

  &__make-and-model {
    grid-area: make-and-model;
    @apply justify-self-start;
  }

  &__price {
    grid-area: price;
  }

  &__weekly {
    grid-area: weekly;

    @apply flex;
    @apply flex-row;
    @apply relative;
  }

  &__est-tax-savings {
    grid-area: est-tax-savings;
  }

  &__help-icon-wrapper {
    @apply absolute;
    @apply h-full;
    @apply flex;
    @apply items-center;
    @apply -right-6;

    @screen md {
      @apply -right-8;
    }
  }
}
