@use '/styles/variables.scss' as *;

.d-athena-search-modal-overlay {
  &::before {
    content: '';
    @apply z-[-1];
    @apply w-full-screen;
    @apply h-screen;
    @apply fixed;
    @apply left-0;
    @apply top-0;
    @apply bg-black;
    @apply opacity-50;
  }
}
.d-athena-search-modal {
  @apply absolute;
  @apply left-1/2;
  top: $header-height-mobile;
  @apply -translate-x-1/2;
  @apply flex;
  @apply flex-col;
  @apply gap-8;
  @apply w-full;
  @apply max-w-[1010px];
  @apply bg-blue-darkest;
  z-index: $z-index-athena-search-modal;
  @apply px-4;
  @apply py-5;
  @apply rounded-t-none;
  @screen lg {
    @apply rounded-b-2xl;
  }
  @screen xl {
    top: $header-height-desktop;
    @apply p-4;
  }

  @apply absolute;
  @apply left-1/2;
  @apply -translate-x-1/2;
  @apply w-full;
  @apply max-w-[1010px];
  @apply bg-blue-darkest;

  :global(#athena-modal-search) {
    [class*='dropdown__control'] {
      @apply rounded-[10px];
      @apply bg-white;
    }

    [class~='dropdown__menu'] {
      @apply bg-white;
      @apply rounded-b-[10px];
      @apply -mt-2;
    }

    [class~='dropdown__menu-list'] {
      @apply bg-inherit;
      @apply rounded-b-[10px];
    }

    [class~='dropdown__option'] {
      @apply bg-inherit;
    }
    [class*='dropdown__option--is-focused'] {
      @apply bg-gray-200;
    }
  }

  &--hidden {
    @apply hidden;
  }
  &__suggestions {
    @apply flex;
    @apply flex-col;
    @apply gap-12;
    @apply px-4;
    @apply pb-8;
    @apply bg-blue-darkest;
    @apply text-white;
    @screen md {
      @apply flex-row;
      @apply px-9;
    }
  }
}
