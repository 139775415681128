@use '/styles/mixin/fonts.module.scss' as *;

.d-athena-search-box {
  @apply min-w-full;
  @apply flex;
  @apply bg-white;
  @apply rounded-[10px];

  &__select {
    @apply h-14;
    @apply min-w-full;
    @apply text-black;

    // For dropdowns
    [class*='dropdown__control'] {
      @apply h-full;
      @apply min-w-full;
      @apply relative;
      @apply p-4;
      @apply pt-[14px];
      @apply border-none;
      @apply rounded-[10px];
      @screen md {
        @apply rounded-2xl;
      }
      @apply overflow-hidden;
      @apply shadow-none;
    }
    :global(.dropdown__menu) {
      @apply mt-3;
      @apply shadow-none;
    }
    :global(.dropdown__menu-list) {
      @apply bg-gray-100;
      @apply max-h-44;
    }
    :global(.dropdown__option) {
      @apply pl-[52px];
    }
    [class^='dropdown__indicators'] {
      @apply hidden;
    }
    [class^='dropdown__input-container'] {
      @apply flex;
      @apply text-black;
      @include font-subtext('desktop', 'medium');
      @apply py-0;
      line-height: 20px;
    }
    [class^='dropdown__value-container'] {
      @apply h-6;
      @apply text-black;
      @apply ml-[34px];
      @apply py-0;
      @apply pl-0;
    }
    &--loading {
      @apply opacity-60;
      @apply pointer-events-none;
      @apply select-none;

      [class^='dropdown__input-container'] {
        @apply caret-transparent;
      }
    }
  }

  &__clear-button {
    @apply -translate-x-[200%];
    @apply hidden;
    &--show {
      @apply flex;
    }
    @apply items-center;
    @apply cursor-pointer;
    &__text {
      @apply opacity-0;
      @apply bg-white;
      @apply text-gray-400;
      @screen md {
        @apply opacity-100;
      }
    }
    & img {
      @apply bg-white;
    }
  }
  &__search-icon {
    @apply translate-x-full;
    @apply translate-y-3/4;
    @apply z-4;
    @apply p-0;
    @apply absolute;
  }
}
