.d-vehicle-container {
  @apply flex;
  @apply flex-col;
  @apply justify-center;

  &__title {
    @apply flex;
    @apply gap-2;
    @apply text-xxs;
    @apply font-bold;
    @apply items-center;
    @apply m-auto;
    @apply text-gray-400;
  }
}

.d-vehicle-list {
  @apply border;
  @apply border-gray-300;
  @apply border-solid;
  @apply rounded-2xl;
  @apply overflow-hidden;
  @apply mt-4;
  @apply mb-4;

  & > *:not(:last-child) {
    @apply border-b;
    @apply border-gray-300;
    @apply border-solid;
  }
}
