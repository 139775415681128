@use '/styles/mixin/categoryLabel.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;

.drive-cfs__lightbox-form {
  &__form {
    &-fields {
      @apply w-full;
      @apply flex;
      @apply justify-between;
      @apply flex-row;
      @apply flex-wrap;
      @apply gap-y-6;
      @apply gap-x-4;
      @apply text-gray-600;

      :global(.firstName),
      :global(.lastName) {
        @apply w-[148px];
      }

      :global(.phone) {
        @apply max-w-[190px];

        [class^='animated-input_drive-form-animated-input-container'] {
          @apply pl-1;
          @apply bg-white;
        }

        [class^='animated-input_drive-form-animated-input__input'] {
          width: calc(100% - 24px);
          @apply pr-0;
          @apply pl-3;
        }
      }
      :global(.postcode) {
        @apply w-[106px];
      }

      // Overwrite checkbox input styles
      :global(.checkboxes) {
        :global(.label) {
          @screen md {
            @apply mb-5;
            @apply max-w-full;
            @apply w-full;
          }
        }
      }
    }
  }

  &__buttons {
    @apply mt-6;
  }
}
