@use '/styles/mixin/carousels.module.scss' as *;
@use '/styles/mixin/thumbnail.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/videoPlayicon.module.scss' as *;

.drive-review__video-player {
  &__external-player {
    @apply w-full;
    @apply relative;
    padding-bottom: 56.25%;
    margin-top: 30px;
  }

  &__container {
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply p-0;
  }

  &__wrapper {
    @apply w-full;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @screen md {
      @apply max-w-screen-md;
    }
    @screen lg {
      @apply max-w-screen-xl;
    }

    /* Resize video player in landscape mode */
    @media (min-width: 420px) and (max-width: 980px) and (max-height: 480px) {
      [class^='videoPlayer_drive-review__video-player__external-player'] {
        max-width: 80%;
        @apply relative;
        @apply h-full;
        @apply pb-0;
        @apply mt-0;
      }
      @apply h-full;
    }
  }

  &__close-btn {
    @apply text-white;
  }

  &__play-btn-wrapper {
    @apply absolute;
    @apply inset-0;
    @apply flex;
    @apply items-center;
    @apply justify-center;
  }

  &__play-btn-wrapper-background {
    @apply absolute inset-0 flex items-center justify-center;
    > img {
      @apply bg-white rounded-full opacity-100;
    }
  }

  &__play-btn-wrapper-drivetv {
    @apply absolute;
    @apply inset-0;
    @apply flex;
    @apply items-center;
    @apply justify-center;
  }
  &__play-icon {
    @include video-icon-dimension('medium');
    @screen lg {
      @include video-icon-dimension('xLarge');
    }
    @apply text-gray-300;
    @apply text-6xl;
    @apply transition-opacity;
    @apply duration-500;
    @apply opacity-75;
    &:hover {
      @apply opacity-100;
    }
  }
  &__play-btn {
    @apply w-full;
    @apply relative;
  }
  &__img-figure {
    @apply w-full;
    @apply h-full;
  }
  &__img-wrapper {
    @apply relative;
    @apply w-full;
    @apply h-full;
  }

  &__img {
    @apply object-cover;
    @apply h-full;
    @apply w-full;
  }
  &__img-only {
    @apply object-cover;
    @apply h-full;
    @apply w-full;
  }

  &__block-img {
    @apply cursor-pointer;
    @apply object-cover;
    @apply h-full;
    @apply w-full;
  }

  &__caption {
    @apply relative;
    @apply mt-2;
    @apply pointer-events-none;
    @apply cursor-default;
    @apply text-left;
    @apply text-black;
    @apply leading-4;
    @apply text-sm;
    @apply font-medium;
  }

  &__modal {
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply w-full;
    @apply flex;
    @apply justify-start;
    @apply p-10;
  }

  &__modal-anchor {
    @apply h-full;
    @apply w-full;
  }
  &__gallery {
    @include gallery;
  }
  &__gallery-mb-4 {
    @apply mb-4;
  }
  &__thumbnail-category {
    @include article-category;
    @include font-label;
    @apply bottom-0;
    @apply -mb-3;
  }
  &__thumbnail-category-mobile-only {
    @screen lg {
      @apply hidden;
    }
  }
  &__thumbnail-category-desktop-only {
    @apply hidden;
    @screen lg {
      @apply block;
    }
  }
}
