@use '/styles/1-settings/colors' as *;

@mixin article-thumbnail {
  max-width: 20rem;
  height: 21.5rem;
  @apply min-w-72;
  @apply w-full;
  @apply flex;
  @apply flex-col;
  @apply border;
  @apply border-gray-200;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);

  @screen md {
    max-width: 25.125rem;
    height: 24.5rem;
  }

  &:global(.no-border) {
    @apply relative;
    @apply mb-2;
    @apply shadow-none;
    @apply border-none;
    @apply border-gray-300;

    &:after {
      content: '';
      @apply absolute;
      @apply left-0;
      @apply right-0;
      @apply bottom-0;
      @apply border-b;
      @apply -mb-4;
      @apply border-gray-300;
    }
  }

  :global(.figure) {
    @include article-thumbnail-figure;
  }

  .category {
    @include article-category;
  }
}

@mixin article-thumbnail-figure {
  height: 11.25rem;

  @screen md {
    height: 14.063rem;
  }

  @apply w-full;
  @apply bg-gray-100;
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

@mixin article-thumbnail-compact {
  height: 6rem;
  @apply max-w-320;
  @apply m-0;
  @apply p-0;
  @apply bg-white;
  @apply flex-row;
  filter: none;
  @apply min-w-72;
  @apply w-full;
  @apply flex;
  @apply flex-1;
  @apply border;
  @apply border-gray-200;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
}

@mixin article-category-compact {
  line-height: 1.25rem;
  height: 1.25rem;
  padding-left: 0.563rem;
  padding-right: 0.25rem;
  @apply absolute;
  @apply left-0;
  @apply text-white;
  @apply font-bold;
  font-size: 0.625rem;
  @apply uppercase;
  @apply bg-black;
  @apply leading-4;
  @apply z-10;

  &:after {
    border-left: 0.75rem solid $black;
    border-right: 0.75rem solid transparent;
    border-bottom: 1.25rem solid transparent;
    right: -1.438rem;
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin-left: 100%;
  }
}

@mixin article-category {
  @apply absolute;
  @apply left-0;
  @apply text-white;
  @apply font-bold;
  font-size: 0.625rem;
  @apply uppercase;
  @apply bg-black;
  @apply leading-4;
  @apply z-10;
  height: 1.563rem;
  line-height: 1.563rem;
  padding-left: 0.563rem;
  padding-right: 0.25rem;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 1rem solid $black;
    border-right: 1rem solid transparent;
    border-bottom: 1.563rem solid transparent;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin-left: 100%;
  }
}
