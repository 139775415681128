@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  @apply space-y-6;
  @apply w-full;
  &__header {
    @apply flex;
    @apply items-center;
    @apply gap-2;
  }
  &__history-icon-wrapper {
    @apply w-4;
    @apply h-4;
  }
  &__header-title {
    @include font-text('mobile', 'medium');
    @apply m-0;
    @apply text-blue-light;
    @screen md {
      @include font-text('desktop', 'medium');
    }
    &--light {
      @apply text-gray-400;
    }
  }
  &__previous-list {
    @apply space-y-4;
  }
  &__previous-list-item {
    @apply flex;
    @apply gap-2;
    @apply cursor-pointer;
    p {
      @include font-subtext('mobile', 'large');
      @apply m-0;
      @screen md {
        @include font-subtext('desktop', 'large');
      }
    }
    &--light {
      p {
        @apply text-blue-dark;
      }
    }
  }
}
