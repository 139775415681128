@use '/styles/mixin/fonts.module.scss' as *;

.drive-novated-lease-form__confirmation-message {
  @apply w-full;
  max-height: 88%;

  [class^='confirmation-message_drive-cfs__confirmation-message__wrapper'] {
    @apply bg-blue-faint;
  }

  [class^='form-details_drive-form-details__details-form__wrapper'] {
    @screen lmd {
      @apply flex;
      @apply flex-row;
      @apply items-start;
    }
    > div {
      @screen lmd {
        @apply w-1/2;
      }
    }
  }
  [class^='form-details_drive-form-details__details-form__message'] {
    @apply hidden;
  }
}
